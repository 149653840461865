import React from 'react'

import { HowToBeInterBlack } from './style'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import ConfortoEExclusividadeJSON from '../../assets/data/conforto-e-exclusividade.json'
interface IConfortoEExclusividade {
  icon: string;
  description: string;
}

const ConfortoEExclusividade = () => {
  return (
    <HowToBeInterBlack
      id='conforto-e-exclusividade'
      className='py-5 d-flex align-items-end align-items-md-center'
      role='img'
      aria-label='Área de acesso ao Vip Lounge Inter'
    >
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-7 offset-md-5 col-lg-6 offset-lg-6 px-0'>
            <h2 className='fs-28 lh-34 fs-md-40 lh-md-44 fs-lg-48 lh-lg-52 fs-xl-56 lh-xl-60 text-white font-citrina fw-600'>
              <span className='d-block d-xl-inline'>Conforto e </span>  exclusividade pra você.
            </h2>
            <ul className='row mb-0'>
              {ConfortoEExclusividadeJSON.map((item: IConfortoEExclusividade, index: number) => (
                <li className='col-12 col-lg-12 mb-2' key={index}>
                  <div className='d-flex align-items-center icon'>
                    <div className='mr-4'>
                      <OrangeDsIcon size='MD' color='#FFFFFF' icon={item.icon} />
                    </div>
                    <p
                      className='fs-14 lh-17 fs-xl-18 lh-xl-22 text-white fw-400 mb-0'
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </HowToBeInterBlack>
  )
}

export default ConfortoEExclusividade
