import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const HowToBeInterBlack = styled.section`
  background-color: #72370E;
  height: 660px;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-xl-black/image.webp');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: -50px;

  @media (min-width: 370px) {
    height: 710px;
  }

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-md-black/image.webp');
    background-repeat: no-repeat;
    background-size: auto;
    height: 460px;
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-black-lg-1/image.webp');
    background-repeat: no-repeat;
    background-size: auto;
    height: 530px;
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-xl-black-1/image.webp');
    background-repeat: no-repeat;
    background-size: auto;
    height: 530px;

    h2 {
      letter-spacing: -1px;
    }
  }

  ul {
    list-style-type: none;
  }
`
