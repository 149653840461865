import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { HowToBeInterBlack } from './style'

type SuaVidaFinanceiraMaisInteligenteProps = {
  setIsModalOpenAccount: Function;
  setDataLayer: Function;
}

const SuaVidaFinanceiraMaisInteligente = ({ setDataLayer, setIsModalOpenAccount }: SuaVidaFinanceiraMaisInteligenteProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_07',
    section_name: 'Sua vida financeira mais inteligente com o Super App Inter',
    element_action: 'click button',
    element_name: 'Baixe agora',
  }

  return (
    <HowToBeInterBlack
      className='py-5 d-flex align-items-md-center'
      role='img'
      aria-label='Mulher sentada mexendo no celular. Ao lado dela, está uma mala de viagem verde'
    >
      <div className='container d-flex align-items-end align-items-md-center'>
        <div className='row'>
          <div className='col-12 col-md-7'>
            <h2 className='fs-28 lh-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-white fw-600 mb-1'>
              Sua vida financeira <span className='d-block'>mais inteligente com</span> o Super App Inter
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-white'>
              Transferências, compras, investimentos e muito mais. Segurança e praticidade sem trocar de app.
            </p>
            <div className='d-flex justify-content-md-center justify-content-lg-start'>
              <button
                aria-label='Baixe agora'
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3 mw-100'
                onClick={() => {
                  setIsModalOpenAccount(true)
                  setDataLayer(dataLayer)
                  sendDatalayerEvent(dataLayer)
                }}
              >
                Baixe agora
              </button>
            </div>
          </div>
        </div>
      </div>
    </HowToBeInterBlack>
  )
}

export default SuaVidaFinanceiraMaisInteligente
