import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const HowToBeInterBlack = styled.section`
  height: 780px;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/louge-key-sm/image.webp');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: ${grayscale['500']};

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-lougekey-md/image.webp');
    background-repeat: no-repeat;
    background-size: auto;
    height: 510px;
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-lougekey-lg/image.webp');
    background-repeat: no-repeat;
    background-size: auto;
    height: 580px;
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/louge-key-xl/image.webp');
    background-repeat: no-repeat;
    background-size: auto;
    background-color: ${grayscale['500']};
    height: 580px;
  }
`

export const Button = styled.button`
  @media ${device.desktopLG} {
    width: 90%;
  }
`
