import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  height: calc(100vh - 64px);

  @media ${device.tablet} {
    height: calc(100vh - 73px);
  }

  iframe {
    border: 0;
  }
`
