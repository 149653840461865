import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { Button, HowToBeInterBlack } from './style'

type LoungeKeyProps = {
  setIsModalOpenAccount: Function;
  setDataLayer: Function;
}

const LoungeKey = ({ setDataLayer, setIsModalOpenAccount }: LoungeKeyProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_06',
    section_name: 'LoungeKey Black Mastercard. 1300 Salas VIP pelo mundo. 50 Salas VIP pelo Brasil.',
    element_action: 'click button',
    element_name: 'Peça já o seu',
  }

  return (
    <HowToBeInterBlack
      className='py-5 d-flex align-items-end align-items-md-center'
      role='img' aria-label='Sala Vip Inter no Aeroporto de Curitiba'
    >
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-7 offset-md-5 col-lg-7 offset-lg-5'>
            <h2 className='fs-28 lh-34 fs-lg-48 lh-lg-52 text-white font-citrina fw-700'>
              <span className='d-block fw-600 fs-xl-56 lh-xl-61'>Priority Pass</span>
              <span className='d-block d-md-inline fs-lg-40 lh-lg-44'> 1.500 experiências</span> premium ao redor do mundo
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-white'>
              Viagens inesquecíveis e todas as vantagens de ser Black.
              <br /><br />
              Tudo pensado para garantir mais conforto e exclusividade enquanto você espera pelo seu voo.
              <br /><br />
              Verifique a lista completa de salas VIP no site da <a href='https://www.prioritypass.com/pt' className='text-white' rel='noreferrer' target='_blank' title='Priority Pass.'>Priority Pass.</a>
            </p>

            <div className='d-flex justify-content-md-center justify-content-lg-start'>
              <Button
                aria-label='Peça já o seu'
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3 mw-100'
                onClick={() => {
                  setIsModalOpenAccount(true)
                  setDataLayer(dataLayer)
                  sendDatalayerEvent(dataLayer)
                }}
              >
                Peça já o seu
              </Button>
            </div>
          </div>
        </div>
      </div>
    </HowToBeInterBlack>
  )
}

export default LoungeKey
