import React from 'react'

import { Section } from './style'

const TourVirtual = () => {
  return (
    <Section className=''>
      <iframe
        width='100%' height='100%'
        allow='xr-spatial-tracking; gyroscope; accelerometer'
        allowFullScreen
        src='https://kuula.co/share/collection/7Kf1R?logo=-1&info=1&fs=1&vr=0&sd=1&gyro=0&audio=0&thumbs=1&inst=0'
      />
    </Section>
  )
}

export default TourVirtual
