import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import { MasterCardWithCashback } from './style'

const ComoAproveitar = () => {
  const imageWidths: number[] = [
    283.65, 283.65, 348, 536, 536,
  ]
  const imageWidthsPromotional: number[] = [
    296.69, 296.69, 364, 536, 536,
  ]
  const imageHeight: number[] = [
    250, 284.47, 349, 436, 436,
  ]

  return (
    <MasterCardWithCashback className='pt-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h2 className='fs-28 lh-34 fs-md-40 lh-md-44 fs-lg-48 lh-lg-52 fs-xl-56 lh-xl-60 text-white fw-600 font-sora mb-4'>
              Como aproveitar
            </h2>
            <p className='fs-16 lh-20 fs-md-18 lh-md-22 fs-lg-24 lh-lg-28 text-white fw-600 mb-4'>
              Retire o QR Code de acesso pelo Super App e apresente junto do cartão de embarque, até 4 horas antes do seu voo.
            </p>
          </div>

          <div className='col-12 col-md-6 mt-2'>
            <div className='text-center'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/salavip-dobra4-cartao1/image.webp'
                altDescription='Área de acesso ao Vip Lounge Inter'
                arrayNumbers={imageWidths}
                arrayNumbersHeight={imageHeight}
              />
            </div>

            <h2 className='fs-24 lh-28 fw-600 text-white font-citrina mt-4'>
              Cartão Black e Win
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-white'>
              Os clientes Win e Inter Black tem acesso ilimitado aos Inter Lounge Vip.
            </p>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-white'>
              E além disso, tem uma experiência ainda mais exclusiva, com gestão de patrimônio e especialistas em investimentos.
              Para fazer parte, você precisa ter a partir de R$ 250.000 investidos no Inter.
            </p>
          </div>

          <div className='col-12 col-md-6'>
            <div className='text-center'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/salavip-dobra4-cartao2/image.webp'
                altDescription='Sala Vip Inter no Aeroporto de Guarulhos'
                arrayNumbers={imageWidthsPromotional}
                arrayNumbersHeight={imageHeight}
                className='mb-3 mb-md-2'
              />
            </div>

            <h2 className='fs-24 lh-28 text-white fw-600 font-citrina mt-4'>
              Cartão Black Promocional
            </h2>

            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-white'>
              Quem adquire um cartão Black por campanhas promocionais, assinatura Inter Duo Gourmet ou gastos na fatura,
              ganha acesso gratuito ao Vip Lounge Inter, seis vezes por ano.
            </p>
          </div>
        </div>
      </div>
    </MasterCardWithCashback>
  )
}

export default ComoAproveitar
