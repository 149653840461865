import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const HowToBeInterBlack = styled.section`
  position: relative;
  height: 680px;
  background-color: #72370E;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/background-sua-vida-fin-inteligente/image.webp');
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 380px) {
    height: 730px;
  }

  ::before {
    @media ${device.tablet} {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 62%; // Adjust this to change the width of the mask
      background: #72370E; // Adjust the last value to change the opacity of the mask
    }

    @media ${device.desktopLG} {
      width: 58%; // Adjust this to change the width of the mask
    }
  }

  @media ${device.tablet} {
    background-position-x: 392px;
    background-size: contain;
    height: 510px;
  }

  @media ${device.desktopLG} {
    background-position-x: right;
    height: 470px;
  }

  @media ${device.desktopXL} {
    background-size: 580px 580px;
    height: 580px;
  }
`
