import React from 'react'

import ScrollTo from 'src/components/ScrollTo'
import { ContentHeader } from './style'

const Header = () => {
  return (
    <ContentHeader
      className='py-5 py-md-5 d-flex align-items-end align-items-md-center'
      role='img'
      aria-label='Sala Vip Inter no Aeroporto de Guarulhos'
    >
      <div className='container'>
        <div className='row justify-content-center justify-content-md-start'>
          <div className='col-12 col-md-6 col-lg-6'>
            <h1 className='fs-28 lh-34 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-56 lh-xl-60 text-white mt-5 mt-md-0 pt-3 pt-md-0'>
              <span className='d-block fw-700'>VIP Lounge Inter.</span>
              <span className='fw-500 d-lg-block'>Sua viagem ainda </span>mais inesquecível.
            </h1>
            <ScrollTo
              to='#conforto-e-exclusividade'
              section='dobra_01'
              sectionName='VIP Lounge Inter. Sua viagem ainda mais inesquecível.'
              elementName='Conheça os benefícios'
              styles='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3 mw-100'
              title='Conheça os benefícios'
            >
              Conheça os benefícios
            </ScrollTo>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Header
