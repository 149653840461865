import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const MasterCardWithCashback = styled.section`
  background-color: ${grayscale[500]};
`

export const Button = styled.button`
  @media (min-width: ${breakpoints.md}) {
    width: 60%;
  }
`
