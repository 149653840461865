import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  height: 600px;
  position: relative;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/background-hero-sala-vip/image.webp');
  background-repeat: no-repeat;
  background-color: #161616;
  background-size: 100% 394px;
  background-position-y: -33px;

  @media ${device.tablet} {
    background-position-x: 246px;
    background-position-y: 0;
    background-size: contain;
    height: 540px;

    ::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 50%; // Adjust this to change the width of the mask
      background: #161616; // Adjust the last value to change the opacity of the mask
      border-bottom-right-radius: 64px; // Adjust this to change the border radius
    }
  }

  @media ${device.desktopLG} {
    background-repeat: no-repeat;
    background-position-x: 246px;
    height: calc(100vh - 73px); 

    ::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 50%; // Adjust this to change the width of the mask
      background: #161616; // Adjust the last value to change the opacity of the mask
      border-bottom-right-radius: 332px; // Adjust this to change the border radius
    }
  }

  @media ${device.desktopXL} {
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;

    ::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 58%; // Adjust this to change the width of the mask
      background: #161616; // Adjust the last value to change the opacity of the mask
      border-bottom-right-radius: 332px; // Adjust this to change the border radius
    }
  }

  .btn {
    @media ${device.desktopLG} {
      width: 80%;
    }
  }

  .container {
    background-color: #161616;
    border-radius: 0 64px 0 0;

    @media ${device.tablet} {
      border-radius: 0;
      background-color: transparent;
    }
  }
`
